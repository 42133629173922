.loader {
    @apply h-6 w-12;

    display: inline-block;
    background: url("/permutationen/permutation_o_blue.svg") repeat;
    background-size: 3rem;
    background-position: 0 0;
    vertical-align: middle;
    animation: move 6s infinite;
}

@keyframes move {
    50% {
        animation-timing-function: ease-in-out;
        background-position: 0 300px;
    }
}